import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/twentytwo.jpg';

const Twentytwo = () => {
    return (
        <>
            <Helmet>
                <title>Инженер-электроник</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер-электроник</h1>
                        <p>Это специалист, занимающийся разработкой, проектированием и производством электронных компонентов
                            и систем, таких как микросхемы, транзисторы и другие элементы, используемые
                            в электронных устройствах.</p>
                        <p>Эта профессия требует глубоких знаний
                            в области микроэлектроники, схемотехники, технологий производства и свойств
                            электронных материалов.</p>
                        <p><b>Ключевыми задачами инженера-электроника </b>являются исследование
                            и анализ материалов и технологий, проектирование новых электронных компонентов, их тестирование и внедрение
                            в производство. Эта работа требует глубоких технических знаний, умения работать в команде и внимательности
                            к деталям, а также постоянного обновления знаний в быстро меняющейся области электроники.</p>
                        <p>Инженер-электроник занимается разработкой и созданием элементной базы,
                            используемой в различных устройствах.
                            Он работает над созданием микропроцессоров, диодов, транзисторов и других
                            элементов, которые являются основой
                            для более сложных электронных систем.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Twentytwo;