import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/nineteen.jpg';

const Nineteen = () => {
    return (
        <>
            <Helmet>
                <title>Мастер участка</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Мастер участка</h1>
                        <p>Руководитель низшего звена управления.</p>
                        <p>Главной его задачей является организация рабочего процесса своего участка
                            и обеспечение высокой производительности, безопасное и бесперебойное
                            функционирование оборудования. Кроме
                            того, он отвечает за выполнение плана
                            своими рабочими, контролирует технологический процесс и качество труда, организует жизнедеятельность своего
                            участка, несет за него полную ответственность.</p>
                        <p>Мастер участка участвует в разработке
                            новых и совершенствовании действующих технологических процессов и режимов производства, а также производственных графиков, проверяет качество
                            выпускаемой продукции и/или выполняемых работ, осуществляет мероприятия
                            по предупреждению брака и повышению
                            качества продукции (работ, услуг) и так
                            далее.</p>
                        <p>На ГХК, например, в функции мастера
                            участка нестандартизированного оборудования входит организация работ
                            на производстве, расстановка работников по рабочим местам, выдача сменного
                            задания, дальнейший контроль за выпускаемой продукцией совместно с сотрудниками отдела технического контроля,
                            изготовление всех необходимых пеналов
                            для «сухих» хранилищ комбината и отправка их на производство.</p>
                        <p><b>Мастер участка должен знать: </b>нормативно-правовые, методические
                            документы, касающиеся его сферы деятельности, технические характеристики
                            и требования, предъявляемые к продукции, выпускаемой участком, технологию
                            ее производства, оборудование участка,
                            методы технико-экономического и производственного планирования, формы
                            и методы производственно-хозяйственной деятельности участка, трудовое
                            законодательство, порядок тарификации
                            работ, рабочих и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Nineteen;