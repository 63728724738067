import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/seven.jpg';

const Seven = () => {
    return (
        <>
            <Helmet>
                <title>Оператор станков с программным управлением</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Оператор станков с программным управлением</h1>
                        <p>Рабочий высокой квалификации.</p>
                        <p>В его обязанности входит изготовление
                            деталей (обычно металлических)
                            на фрезерном, токарном, сверлильном,
                            шлифовальном, резательном оборудовании, управление которым ведется
                            с помощью программного обеспечения.</p>
                        <p>Чем больше компетенций (знаний,
                            умений, навыков) есть у оператора,
                            тем более творческой будет его работа,
                            но в основном станки с ЧПУ используются для серийного производства деталей.</p>
                        <p>Обычно в обязанности оператора станков с программным управлением входит
                            не менее<b> четырех самых востребованных операций: </b>механическая токарная
                            обработка, фрезерование, сверление,
                            шлифовка.</p>
                        <p>На Горно-химическом комбинате
                            оператор ЧПУ, например, изготавливает
                            деталировку для сборки изделия «ампула
                            ПТ», которая используется для хранения
                            отработавшего ядерного топлива
                            РБМК-1000. Данный специалист обязан
                            уметь обслуживать станки с ЧПУ, писать
                            для них программы, отстраивать эти программы, правильно подбирать инструмент и привязывать его к изготавливаемой детали, вовремя заменять металлорежущие пластины и в соответствии
                            с конструкторской документацией,
                            которая указана на чертежах, изготавливать деталь. На ГХК требования
                            к изготавливаемым ампулам ПТ очень
                            высоки. Поэтому и требования к оператору ЧПУ и тем деталям, которые
                            он изготавливает, тоже высоки.</p>
                        <p><b>Качества, которыми должен обладать
                            оператор станков с ЧПУ: </b>развитое
                            пространственное мышление, хороший
                            глазомер и координация, высокая
                            степень внимания и способность к длительной концентрации, хорошая реакция,
                            профессионализм, умение работать
                            в команде.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Seven;