import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/two.jpg';
import image_1 from '../../assets/image/two_1.jpg';
import image_2 from '../../assets/image/two_2.jpg';
import image_3 from '../../assets/image/two_3.jpg';
import image_4 from '../../assets/image/two_4.jpg';
import image_5 from '../../assets/image/two_5.jpg';

import { Circle } from '../../assets/icon';

const Two = () => {
    return (
        <>
            <Helmet>
                <title>Лаборант</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Лаборант</h1>
                        <p>
                            Специалист, отвечающий за подготовку и
                            проведение различных лабораторных исследований. Он проводит измерения
                            и исследования с использованием
                            специальных приборов и оборудования,
                            готовит приборы, лабораторное оборудование, следит за их исправностью,
                            обеспечивает лабораторию необходимыми материалами, реактивами, записывает
                            показания приборов, регистрирует
                            полученные в ходе исследования
                            результаты и так далее.
                        </p>
                        <b>
                            Профессия лаборанта
                            на Горно-химическом комбинате
                            имеет различные специализации:
                        </b>
                    </div>
                    <div className='img-in-text'>
                        <img src={image_1} alt="Лаборант-коррозионист" />
                        <Circle />
                    </div>
                    <div className='info-block'>
                        <h2>Лаборант-коррозионист</h2>
                        <p>занимается стандартизированными коррозионными испытаниями, которые проводятся в соответствии с установленными
                            ГОСТами и методиками.
                            В его обязанности входит входной
                            контроль приходящих на комбинат
                            металлов, коррозионные и механические
                            испытания, а также обследование технологического оборудования.</p>
                    </div>
                    <div className='img-in-text'>
                        <img src={image_2} alt="Лаборант химического анализа" />
                        <Circle />
                    </div>
                    <div className='info-block'>
                        <h2>Лаборант химического анализа</h2>
                        <p>выполняет химический анализ проб
                            сырья и материалов, которые приходят
                            на комбинат, и технологических продуктов. Он определяет, соответствуют ли они
                            действующим ГОСТам, техническим условиям, технологическим регламентам.
                            Исследует содержание химических
                            элементов в растворах, газах и твердых
                            веществах.</p>
                    </div>
                    <div className='img-in-text'>
                        <img src={image_3} alt="Лаборант по измерению физических и производственных факторов" />
                        <Circle />
                    </div>
                    <div className='info-block'>
                        <h2>Лаборант по измерению физических и производственных факторов</h2>
                        <p>измеряет на рабочих местах сотрудников
                            комбината физические производственные факторы, такие как освещение, микроклимат (температуру, влажность, скорость движения воздуха), шум, вибрацию,
                            электромагнитные поля от компьютеров,
                            аппаратуры и оборудования.</p>
                    </div>
                    <div className='img-in-text'>
                        <img src={image_4} alt="Лаборант спектрального анализа" />
                        <Circle />
                    </div>
                    <div className='info-block'>
                        <h2>Лаборант спектрального анализа</h2>
                        <p>занимается пробоподготовкой растворов,
                            а именно разбавлением, растворением,
                            подготовкой реактивов и проведением
                            измерений на сложнейших
                            масс-спектрометрах.</p>
                    </div>
                    <div className='img-in-text'>
                        <img src={image_5} alt="Лаборант-радиохимик" />
                        <Circle />
                    </div>
                    <div className='info-block'>
                        <h2>Лаборант-радиохимик</h2>
                        <p>анализирует технологические пробы
                            на содержание плутония, америция, цезий,
                            стронция, тритий и так далее.</p>
                        <p>Профессия лаборанта подойдет тем, кто
                            интересуется наукой в частности химией,
                            радиохимией, физической и коллоидной
                            химией а также физикой. Имеет склонность к аналитическому мышлению
                            и хочет работать с современными приборами и оборудованием, проводя
                            исследования и анализируя различные
                            материалы и вещества.</p>
                        <p>Профессия требует внимательности,
                            точности, ответственности, аккуратности.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Two;