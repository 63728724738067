import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/sixteen.jpg';

const Sixteen = () => {
    return (
        <>
            <Helmet>
                <title>Инженер по измерению физических и производственных факторов</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер по измерению физических и производственных факторов</h1>
                        <p>Специалист, выполняющий проведение
                            измерений уровней физических факторов производственной среды (шум,
                            микроклимат и так далее). В его функции
                            также входит анализ полученных результатов измерения, оформление протоколов установленной формы.</p>
                        <p>На ГХК инженер по измерению физических и производственных факторов
                            проводит производственный контроль
                            рабочих мест. Опираясь на проведенные
                            лаборантом исследования физических
                            производственных факторов, таких как
                            микроклимат (температура, влажность,
                            скорость движения воздуха), освещение,
                            шум, вибрация, электромагнитные поля
                            от компьютеров, аппаратуры и оборудования, проводит их нормирование
                            в соответствии с установленными
                            для данного конкретного рабочего места
                            санитарными нормами и правилами.
                            На основе проведенных исследований
                            определяет, есть ли нарушения санпина.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Sixteen;