import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/seventeen.jpg';

const Seventeen = () => {
    return (
        <>
            <Helmet>
                <title>Инженер-физико-химик</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер-физико-химик</h1>
                        <p>Специалист, выполняющий научноисследовательские работы по разработке
                            и совершенствованию физических
                            и спектральных методик анализа сырья,
                            технологических продуктов, материалов
                            и готовой продукции предприятия.</p>
                        <p>Он осуществляет контроль за выполнением физико-химических исследований,
                            проводит проверку правильности выполнения измерений контрольных проб
                            и стандартных образцов, выполняет
                            наиболее ответственные или нестандартные анализы, организует проведение
                            работ, связанные с повышенной опасностью, в том числе и первые опыты с веществами, физико-химические свойства
                            которых малоизученны.</p>
                        <p>Инженер-физико-химик участвует
                            в проведении наиболее ответственных
                            и опасных этапов экспериментальных
                            работ, выдает контрольные пробы и производит проверку правильности методик
                            анализа и так далее.</p>
                        <p>На Горно-химическом комбинате
                            инженер-физико-химик занимается проведением особо сложных анализов,
                            в том числе связанных с переработкой
                            отработавшего ядерного топлива, разработкой и аттестацией методик, контролем работы лаборантов и оборудования,
                            поверкой приборов. Перед проведением
                            особо сложных анализов инженер-физико-химик ведет большую методическую
                            работу, подбирает методы анализа для
                            различных проб, перерабатывает ГОСТы,
                            осуществляет валидацию и верификацию
                            методик исследования. В процессе исследования он проводит аналитический
                            контроль: проверяет, правильно ли с методической точки зрения лаборанты проводят анализы, а также проверяет полученные ими расчеты и результаты.</p>
                        <p><b>Инженер-физико-химик должен
                            знать: </b>нормативно-правовые, методические документы, ГОСТы, ТУ, касающиеся
                            его сферы деятельности, порядок,
                            методы организации и проведения
                            исследовательских работ, планирования
                            экспериментов, технические требования,
                            предъявляемые к оборудованию, сырью,
                            материалам, готовой продукции
                            и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Seventeen;