import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Layout, One, Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten, Eleven, Twelve, Thirteen, Fourteen, Fifteen, Sixteen, Seventeen, Eighteen, Nineteen, Twenty, Twentyone, Twentytwo, Twentythree } from './pages';
import { Ledokol, Ledokol2, Ledokol3, Ledokol4, Ledokol5, Ledokol6 } from './poemsPages';

const AppRouter = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path='/1' element={<One />} />
                <Route path='/2' element={<Two />} />
                <Route path='/3' element={<Three />} />
                <Route path='/4' element={<Four />} />
                <Route path='/5' element={<Five />} />
                <Route path='/6' element={<Six />} />
                <Route path='/7' element={<Seven />} />
                <Route path='/8' element={<Eight />} />
                <Route path='/9' element={<Nine />} />
                <Route path='/10' element={<Ten />} />
                <Route path='/11' element={<Eleven />} />
                <Route path='/12' element={<Twelve />} />
                <Route path='/13' element={<Thirteen />} />
                <Route path='/14' element={<Fourteen />} />
                <Route path='/15' element={<Fifteen />} />
                <Route path='/16' element={<Sixteen />} />
                <Route path='/17' element={<Seventeen />} />
                <Route path='/18' element={<Eighteen />} />
                <Route path='/19' element={<Nineteen />} />
                <Route path='/20' element={<Twenty />} />
                <Route path='/21' element={<Twentyone />} />
                <Route path='/22' element={<Twentytwo />} />
                <Route path='/23' element={<Twentythree />} />
            </Route>
            <Route path='/ledokol' element={<Ledokol />} />
            <Route path='/ledokol2' element={<Ledokol2 />} />
            <Route path='/ledokol3' element={<Ledokol3 />} />
            <Route path='/ledokol4' element={<Ledokol4 />} />
            <Route path='/ledokol5' element={<Ledokol5 />} />
            <Route path='/ledokol6' element={<Ledokol6 />} />
        </Routes>
    </Router>
);

export default AppRouter;