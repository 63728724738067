import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/eight.jpg';

const Eight = () => {
    return (
        <>
            <Helmet>
                <title>Наладчик станков и манипуляторов с программным управлением</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Наладчик станков и манипуляторов с программным управлением</h1>
                        <p>Квалифицированный рабочий,
                            отвечающий за настройку и техническое
                            обслуживание станков и манипуляторов,
                            оснащенных программными системами
                            управления.</p>
                        <p>Он определяет конфигурацию параметров работы, осуществляет постоянный
                            мониторинг функционирования станков,
                            отвечает за решение возможных технических проблем.</p>
                        <p><b>В его функции входит: </b>наладка станка,
                            проверка правильности его установки,
                            отладка и изготовление пробных деталей, корректировка режимов резания,
                            выявление неисправностей, наладка
                            захватов манипуляторов и штабелеров,
                            проверка точности станков и так далее.</p>
                        <p>На ГХК наладчик станков и манипуляторов с программным управлением занимается написанием программ для станков ЧПУ и резкой металла.</p>
                        <p><b>Наладчик должен знать: </b>способы
                            наладки всех систем обслуживаемого
                            станка, способы проверки оборудования
                            на точность, уметь читать чертежи, определять классы точности и шероховатости
                            обработки, владеть навыками создания
                            управляющих программ.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Eight;