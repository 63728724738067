import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/twelve.jpg';

const Twelve = () => {
    return (
        <>
            <Helmet>
                <title>Станочник широкого профиля</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Станочник широкого профиля</h1>
                        <p>Специалист в области металлообработки,
                            обладающий широким спектром навыков
                            и компетенций в работе с различными
                            станками и оборудованием для обработки металлических заготовок.</p>
                        <p>Он осуществляет разнообразные операции по формированию, обработке и отделке деталей из металла с использованием различных станочных методов.</p>
                        <p>Профессия станочника широкого профиля подойдет людям, которые обладают
                            определенными склонностями, интересами и качествами. Среди них техническое мышление, точность и внимательность, работоспособность, умение читать
                            чертежи, терпение и настойчивость,
                            интерес к технике и металлообработке,
                            умение работать в команде, навыки
                            обслуживания оборудования.<b> Эти качества и навыки </b>могут способствовать успешной карьере станочника
                            в различных отраслях промышленности.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Twelve;