import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/ten.jpg';

const Ten = () => {
    return (
        <>
            <Helmet>
                <title>Слесарь по сборке металлоконструкций</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Слесарь по сборке металлоконструкций</h1>
                        <p>Рабочая специальность, представители
                            которой занимаются сборкой и установкой металлоконструкций из листового,
                            сортового и фасонного проката, а также
                            обеспечивают качество и производительность сборки металлоконструкций.</p>
                        <p>Он обладает знаниями и навыками
                            в области монтажа, ремонта и технического обслуживания металлических
                            элементов и конструкций.</p>
                        <p><b>Его основные функции: </b>чтение чертежей, измерение и маркировка, резка
                            и обработка металла, сварка, монтаж
                            и установка, контроль качества,
                            техническое обслуживание.</p>
                        <p>Например, на ГХК слесарь по сборке
                            металлоконструкций подготавливает
                            детали ампулы ПТ, в которых хранится
                            отработавшее ядерное топливо реакторов РБМК-1000, для сварки, сборки.
                            Он также собирает итоговую ампулу,
                            зачищает электроинструментами
                            все сварочные швы и детали, где были
                            резы и сверления, перед тем, как передать готовое изделие «ампулу ПТ»
                            на проверку контролерам отдела технического контроля. Кроме того, слесарь
                            проводит грузовые испытания ампулы ПТ,
                            которая должна выдерживать груз 100
                            килограмм не менее 2-3 минут.
                            При необходимости он контролирует
                            на поверочном столе корпус ампулы
                            по длине, по прямолинейности, по симметричности, доводит готовую ампулу до
                            нужного размера. Если изделие требует
                            доработки, то он дорабатывает его.</p>
                        <p>Профессия слесаря по сборке металлоконструкции подходят людям, которые
                            интересуются техническими предметами,
                            умеют читать чертежи и схемы, имеют хорошую координацию движения, обладают силой и выносливостью, внимательны
                            и ответственны, обладают трудолюбием
                            и упорством, имеют навыки работы с инструментами.</p>
                        <p><b>Личные качества: </b>аккуратность,
                            терпение, четкость, ответственность
                            и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Ten;