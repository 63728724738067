import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/eleven.jpg';

const Eleven = () => {
    return (
        <>
            <Helmet>
                <title>Аппаратчик</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Аппаратчик</h1>
                        <p>Специалист, который работает в различных отраслях промышленности, отвечает
                            за обслуживание и контроль работы
                            технологического оборудования
                            и аппаратов.</p>
                        <p>Работа данного специалиста направлена
                            на обеспечение безопасной и эффективной работы производственных
                            процессов.</p>
                        <p>Профессия аппаратчика имеет<b> важное
                            значение </b>для обеспечения стабильной
                            и безаварийной работы промышленных
                            производств, а также для производства
                            высококачественной продукции.</p>
                        <p>Профессия аппаратчика может подойти
                            людям, которые обладают определенными качествами и интересами. Среди них
                            технические навыки, точность и внимательность, ответственность, умение
                            работать в команде и решать проблемы,
                            интерес к производственным процессам,
                            готовность к обучению и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Eleven;