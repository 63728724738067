import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/twentyone.jpg';

const Twentyone = () => {
    return (
        <>
            <Helmet>
                <title>Инженер по автоматизированным системам управления технологических процессов</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер по автоматизированным системам управления технологических процессов</h1>
                        <p>Специалист, который занимается проектированием, монтажом, наладкой,
                            эксплуатацией и обслуживанием автоматизированных систем управления
                            технологическими процессами (АСУ ТП).
                            АСУ ТП — это комплекс средств, обеспечивающих автоматическое управление
                            и контроль за ходом технологических
                            процессов в различных отраслях
                            промышленности.</p>
                        <p><b>Основные функции инженера АСУ ТП: </b>разработка и проектирование систем АСУ
                            ТП, выбор и подбор оборудования, программирование контроллеров и систем
                            управления, тестирование и отладка
                            систем, внедрение и настройка автоматизированных систем на производстве,
                            поддержка и обслуживание данных
                            систем, обеспечение их кибербезопасности и так далее.</p>
                        <p>Профессия инженера АСУ ТП подойдет
                            тем, кто интересуется техникой, информатикой, физикой и математикой.
                            Это профессия для творческих и аналитических умов, которые способны решать
                            сложные задачи и находить оптимальные
                            решения.</p>
                        <p>Инженер АСУ ТП должен быть внимательным, ответственным, коммуникабельным
                            и стрессоустойчивым. Он должен постоянно совершенствовать свои знания
                            и навыки, следить за новшествами
                            в области автоматизации и технологии.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Twentyone;