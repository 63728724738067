import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/thirteen.jpg';

const Thirteen = () => {
    return (
        <>
            <Helmet>
                <title>Слесарь по контрольно-измерительным приборам и автоматике</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Слесарь по контрольно-измерительным приборам и автоматике</h1>
                        <p>Специалист, который занимается монтажом, наладкой, ремонтом, обслуживанием и проверкой различных приборов,
                            устройств и систем, которые используются для измерения, контроля, регулирования и автоматизации процессов
                            в промышленности, энергетике, транспорте и других сферах деятельности.</p>
                        <p>Профессия слесаря КИПиА подойдет тем,
                            кто интересуется техникой, электроникой, физикой и математикой, имеет хорошую память, внимание, логическое мышление и аналитические способности, обладает умением работать руками,
                            инструментами и оборудованием, может
                            работать самостоятельно и в команде,
                            ответственно и аккуратно, готов к постоянному обучению и повышению квалификации, к работе на высоте
                            и с электричеством.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Thirteen;