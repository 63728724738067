import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/fifteen.jpg';

const Fifteen = () => {
    return (
        <>
            <Helmet>
                <title>Инженер-исследователь</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер-исследователь</h1>
                        <p>Специалист, занимающийся инженерноисследовательской деятельностью
                            в различных областях науки
                            и инженерии.</p>
                        <p><b>В обязанности </b>инженера-исследователя
                            входит выполнение экспериментов,
                            испытаний, наблюдений, техническое
                            обеспечение исследований, разработка
                            предложений по улучшению, сбор,
                            анализ и систематизация научно-технической документации по различным
                            исследовательским темам, составление
                            рабочих планов и программ проведения
                            работ, систематизация исходных данных
                            для составления смет, заявок на материалы и оборудования, участие в НИР и ОКР
                            по разработке новой продукции
                            и технике. Он также отвечает за наладку
                            и регулирование сложной
                            и точной аппаратуры.</p>
                        <p>На Горно-химическом комбинате
                            инженер-исследователь занимается
                            инженерно-исследовательской деятельностью в рамках технологического
                            процесса предприятия. То есть рассчитывает, анализирует, исследует и проверяет
                            технологические процессы в лабораторных условиях, которые выполняет комбинат. Например, он занимается нестандартными коррозионными испытаниями
                            или исследованиями всех технологических работ предприятия на пожаро-взрывобезопасность.</p>
                        <p><b>Какими знаниями, умениями, навыками и личными качествами должен обладать инженер-исследователь? </b>Это
                            гибкий ум, хорошая память, логическое
                            мышление, аналитический склад ума,
                            умение прогнозировать, усидчивость, собранность, высокий уровень концентрации, любознательность, аккуратность,
                            нацеленность на результат.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Fifteen;