import React, { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
// import Footer from '../../components/Footer/Footer';

import { Icon } from '../../assets/icon';


const Layout = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [pathname])

    return (
        <>
            <header>
                <Icon />
            </header>
            <Outlet />
            {/* <footer></footer> */}
        </>
    )
}

export default Layout;