import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/three.jpg';

const Three = () => {
    return (
        <>
            <Helmet>
                <title>Оператор радиохимического производства</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Оператор радиохимического производства</h1>
                        <p>Специалист, отвечающий за ведение технологического процесса радиохимического производства.</p>
                        <p>На Горно-химическом комбинате,
                            например, оператор радиохимического
                            производства занимается техподдержкой
                            технологического процесса хранения
                            отработавшего ядерного топлива (ОЯТ)
                            от момента перегрузки до момента
                            передачи отработавшей тепловыделяющей сборки (ОТВС) на хранение в «сухое»
                            хранилище ОЯТ ВВЭР-1000 или на переработку на опытно-демонстрационный
                            центр (ОДЦ). В центральном зале
                            «мокрого» хранилища ВВЭР-1000 он осуществляет транспортно-технологические
                            операции с ОТВС, а именно расхолаживание, перегрузку из транспортных чехлов
                            в чехлы хранения, из чехла хранения
                            в наклонный подъемник и далее в здание
                            «сухого» хранилища ОЯТ ВВЭР-1000
                            или на ОДЦ. С дистанционного щита
                            управления оператор в автоматическом
                            или ручном режиме управляет оборудованием и ведет технологический процесс
                            радиохимического производства.
                            Он также следит за балансом теплового
                            контура здания «мокрого» хранилища.</p>
                        <p className='pick-out'>Чтобы стать <b> оператором РХП </b>
                            достаточно иметь средне-специальное образование, дополнительное
                            обучение сотрудник проходит
                            на самом производстве.</p>
                        <p>Оператор радиохимического
                            производства должен обязательно
                            обладать качествами, такими как
                            <b> ответственность и внимательность. </b>
                            Ведь ему необходимо постоянно следить
                            за технологическим процессом.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Three;