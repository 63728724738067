import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/four.jpg';

const Four = () => {
    return (
        <>
            <Helmet>
                <title>Оператор «горячей» камеры</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Оператор «горячей» камеры</h1>
                        <p>Специалист, занимающийся работами
                            по исследованию состояния высокоактивного оборудования, ядерного топлива, материалов, изделий в «горячей»
                            камере. Он осуществляет дистанционное
                            управление оборудованием «горячей»
                            камеры с пульта управления, выполняет
                            работы с манипулятором, осматривает,
                            контролирует герметичности оболочек
                            отработавших тепловыделяющих сборок
                            (ОТВС), занимается укладкой
                            их в пеналы и так далее.</p>
                        <p>На ГХК операторы «горячей» камеры
                            работают в «сухих» хранилищах отработавшего ядерного топлива (ОЯТ)
                            ВВЭР-1000 и РБМК-1000. Так, например,
                            на «сухом» хранилище ОЯТ ВВЭР-1000
                            оператор выполняет все технологические
                            операции от приема ОТВС до выдачи
                            заполненных пеналов из «горячей»
                            камеры в перегрузочную машину.</p>
                        <b>Работа оператора «горячей» камеры
                            состоит из следующих этапов:</b>
                        <p className='pick-out'>прием отработавших сборок
                            из наклонного подъемника в камеру
                            подготовки ОТВС</p>
                        <p className='pick-out'>фиксация номера сборки</p>
                        <p className='pick-out'>измерение глубины выгорания
                            ядерного топлива</p>
                        <p className='pick-out'>осушка сборки и ее передача
                            в камеру комплектации пеналов
                        </p>
                        <p className='pick-out'>прием в камере комплектации
                            пеналов отработавших сборок
                            и порожних пеналов</p>
                        <p className='pick-out'>комплектация пеналов
                            и их выдача из «горячей» камеры
                            в перегрузочную машину с соблюдением всех необходимых мер
                            безопасности и технологического
                            режима.</p>
                        <p>Весь технологический процесс оператор ведет дистанционно со щита
                            управления или компьютера. Он
                            также осуществляет пуск и остановку оборудования, проверку состояния оборудования, коммуникаций,
                            визуально и по показаниям контрольно-измерительных приборов.</p>
                        <p><b>Оператор «горячей» камеры должен
                            знать: </b>устройство, принцип работы,
                            технические характеристики оборудования «горячей» камеры, методы контроля
                            герметичности оболочки ОТВС, способы
                            дезактивации оборудования и помещения «горячей» камеры, применяемые
                            дезактивирующие растворы, правила
                            работы с радиоактивными веществами,
                            правила применения средств индивидуальной защиты и так далее.
                        </p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Four;