import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/eighteen.jpg';

const Eighteen = () => {
    return (
        <>
            <Helmet>
                <title>Инженер-масс-спектрометрист</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер-масс-спектрометрист</h1>
                        <p>Специалист, выполняющий научноисследовательские работы по разработке
                            и совершенствованию масс-спектрометрических методов анализа сырья, технологических проб, материалов и готовой
                            продукции.</p>
                        <p>Он осуществляет контроль за правильным и точным выполнением методов анализа, проводит проверку правильности
                            выполнения измерений контрольных
                            проб и стандартных образцов, выполняет
                            наиболее ответственные или нестандартные анализы. Спектрометрист организует
                            работу на всех видах масс-спектрометрических, измерительно-вычислительных
                            комплексах с применением различных
                            типов ЭВМ, проводит расчеты по проведенным исследованиям, анализирует
                            и систематизирует полученные
                            результаты и так далее.</p>
                        <p>На ГХК инженер-масс-спектрометрист,
                            например, занимается элементным
                            и изотопным анализом различных жидких
                            проб для нужд опытно-демонстрационного центра, а также для исследовательских целей, для разработки методик
                            исследования. В процессе своей работы
                            он апеллирует микрограммами исследуемого вещества, получая при этом достоверные результаты. В ходе изотопного
                            анализа на масс-спектрометрах исследуемые изотопы превращаются в пучок
                            заряженных частиц, который проходя
                            в магнитном поле делится по отношению
                            массы к заряду. Регистрирующее устройство в итоге определяет соотношение
                            исследуемых изотопов. Элементный
                            анализ позволяет инженеру определить
                            качество и количество вещества
                            в исследуемой пробе.</p>
                        <p><b>Инженер-масс-спектрометрист
                            должен знать: </b>нормативно-правовые,
                            методические документы, касающиеся
                            его сферы деятельности, порядок,
                            методы организации и проведения
                            исследовательских работ, планирования
                            экспериментов, технические требования,
                            предъявляемые к оборудованию, сырью,
                            материалам и готовой продукции
                            и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Eighteen;