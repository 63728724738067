import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/twentythree.jpg';

const Twentythree = () => {
    return (
        <>
            <Helmet>
                <title>Инженер контрольно-измерительных приборов и автоматики</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер контрольно-измерительных приборов и автоматики</h1>
                        <p>Специалист, занимающийся проектированием, монтажом, наладкой, обслуживанием и ремонтом систем контроля,
                            измерений и автоматики различных
                            технологических процессов. Эти системы
                            необходимы для автоматизации, контроля и управления процессами на предприятиях различных отраслей промышленности.</p>
                        <p><b>Инженер КИПиА выполняет следующие функции: </b>проектирование систем
                            КИПиА, монтаж и наладка оборудования,
                            его обслуживание и ремонт, калибровка
                            и поверка приборов, программирование
                            контроллеров, оптимизация технологических процессов, чтение технической
                            документации и так далее.</p>
                        <p>Для тех, кто интересуется профессией
                            инженера КИПиА,<b> важно учитывать, </b>что
                            для успешной карьеры в этой области
                            потребуются определенные<b> навыки
                                и качества. </b>Вот некоторые из них: интерес к технике и технологиям, математические навыки, логическое мышление,
                            коммуникативные навыки и другие качества.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Twentythree;