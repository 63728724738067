import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/one.jpg';

const One = () => {
    return (
        <>
            <Helmet>
                <title>Инженер-радиохимик</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img className='intro__image' src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер-радиохимик</h1>
                        <p>Специалист в области химии, специализирующийся на изучении и исследовании радиоактивных веществ и ядерных процессов. Он проводит прикладные научные исследования и опытноконструкторские работы, экспериментальные и технологические разработки по определению свойств и анализу радиоактивных препаратов применительно ко всем группам радиационной опасности.</p>
                        <p>Инженер-радиохимик разрабатывает
                            методики проведения исследований,
                            осуществляет анализ и обобщение
                            результатов эксперимента и наблюдений,
                            составляет описания, формулирует
                            выводы и практические рекомендации,
                            осуществляет выбор эффективных
                            средств и способов выполнения заданий,
                            полученных от руководителя работ,
                            обеспечивает полноту, своевременность
                            и высокое качество результата
                            и так далее.</p>
                        <p>На Горно-химическом комбинате
                            инженер-радиохимик, например,
                            на опытно-демонстрационном центре
                            занимается ведением сложных химических процессов по переработке
                            отработавшего ядерного топлива и замыканию ядерного топливного цикла,
                            которые включают в себя разработку
                            методик исследования, инструкций,
                            анализ технологических проб ОДЦ,
                            приемку проб на манипулятор
                            и так далее.</p>
                        <p>
                            Профессия инженера-радиохимика
                            подойдет людям, которые обладают
                            <b> следующими склонностями,
                                интересами и качествами:</b> интерес к
                            химии, любознательность, аналитические и математические навыки, точность
                            и внимательность, интерес к научным
                            исследованиям, ответственность, коммуникабельность, творческий подход,
                            мотивация к работе и так далее.
                        </p></div>
                </section>
            </main>
        </>
    )
}

export default One;