import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/five.jpg';

const Five = () => {
    return (
        <>
            <Helmet>
                <title>Машинист крана (крановщик)</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Машинист крана (крановщик)</h1>
                        <p>Рабочая профессия, требующая высокой
                            квалификации и опыта. Основная задача
                            крановщика заключается в управлении
                            крана для поднятия и перемещения
                            грузов и обеспечения монтажных,
                            сборочных, погрузочных работ.</p>
                        <p>На Горно-химическом комбинате,
                            например, крановщик работает
                            на «мокром» и «сухих» хранилищах отработавшего ядерного топлива ВВЭР-1000
                            и РБМК-1000. Он выполняет технологические операции, связанные с транспортными контейнерами ТК-10/ТК-13,
                            с чехлами хранения различных типов
                            (02Х, 02ХМ, 03Х и так далее), с перегрузкой отработавших тепловыделяющих
                            сборок (ОТВС) ВВЭР-1000 из транспортного чехла контейнеров ТК-10/ТК 13
                            в чехлы хранения. Крановщик также
                            занимается установкой/снятием крышки
                            с транспортного контейнера, перемещением загруженных отработавшими
                            тепловыделяющими сборками чехлов
                            хранения в отсеки «мокрого» хранилища,
                            перегрузкой ОТВС в узле примыкания
                            здания «мокрого» хранилища к зданию
                            «сухого» хранилища ОЯТ ВВЭР-1000.
                            В зависимости от разряда (7 или 8
                            разряд) и сложности работ (средняя
                            сложность/сложные работы) машинист
                            крана управляет мостовыми кранами
                            грузоподъемность 25 или 100 тонн, оснащенными различными грузозахватывающими приспособлениями.</p>
                        <p><b>Качества, которыми должен обладать
                            машинист крана: </b>точность, внимательность, мгновенная реакция, коммуникабельность, ответственность, хорошая
                            координация движения, стрессоустойчивость, любовь к технике и механизмам.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Five;