import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/fourteen.jpg';

const Fourteen = () => {
    return (
        <>
            <Helmet>
                <title>Электромонтер</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Электромонтер</h1>
                        <p>Специалист<b> рабочей специальности, </b>который занимается монтажом, обслуживанием, ремонтом и наладкой электрического оборудования и сетей.</p>
                        <p>Он работает с различными системами
                            электроснабжения, электроосвещения,
                            электропривода и другими устройствами,
                            обеспечивающими функционирование
                            электротехнических систем.</p>
                        <p>Электромонтер может работать в в различных отраслях промышленности, где
                            выполняет монтаж электрооборудования, проводит электромонтажные
                            работы, занимается обслуживанием
                            и ремонтом уже существующих электроустановок.</p>
                        <p><b>Он должен обладать </b>знаниями и навыками в области электротехники, строго
                            соблюдать правила безопасности
                            и нормативных требований.</p>
                        <p><b>Профессия электромонтера </b>подойдет
                            для людей, которые интересуются электротехникой, имеют хорошую техническую подготовку и способны работать
                            с электрооборудованием.</p>
                        <p><b>Личные качества: </b>аналитический склад
                            ума, внимательность, ответственность,
                            умение решать проблемы, физическая
                            выносливость, интерес к электротехнике,
                            готовность к обучению.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Fourteen;