import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/six.jpg';

const Six = () => {
    return (
        <>
            <Helmet>
                <title>Дозиметрист</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Дозиметрист</h1>
                        <p>Работник, выполняющий работы
                            по контролю радиационной обстановки
                            и оценке индивидуальных доз облучения.</p>
                        <p>При выполнении должностных обязанностей дозиметрист производит определение работоспособности и чувствительности дозиметрических и радиометрических приборов, с помощью контрольных
                            источников, выбраковку дозиметрической и радиометрической аппаратуры.</p>
                        <p>Осуществляет контроль радиационных
                            параметров на рабочих местах персонала
                            предприятия и в зонах проведения
                            ремонтных работ на технологическом
                            и вспомогательном оборудовании, в том
                            числе мощности дозы ионизирующего
                            излучения, плотности потока частиц
                            ионизирующего излучения, радиоактивного загрязнения поверхностей производственных помещений, оборудования,
                            транспортных средств, территории,
                            кожных покровов, спецодежды и средств
                            индивидуальной защиты персонала,
                            объемной активности газов и аэрозолей
                            в воздухе рабочих зон с помощью переносных пробоотборников и стационарных систем пробоотбора.</p>
                        <p>Проводит дозиметрический и радиометрический контроль при производстве
                            работ повышенной опасности по дозиметрическим нарядам. На основании
                            измеренных данных определяет допустимое время работы в условиях воздействия на персонал ионизирующего излучения и необходимые при этом средства
                            индивидуальной защиты.</p>
                        <p>Как ответственный за радиационный контроль, контролирует выполнение мероприятий, соблюдение мер предосторожности, обеспечивающих безопасное производство работ по дозиметрическим
                            нарядам. Производит статистическую
                            обработку результатов дозиметрических
                            и радиометрических измерений.</p>
                        <p>На Горно-химическом комбинате
                            дозиметристы работают на всех основных
                            радиационно-опасных производственных объектах.</p>
                        <p><b>Качества, которыми должен обладать
                            дозиметрист: </b>аналитические навыки,
                            коммуникабельность, этическая ответственность, готовность к обучению,
                            повышению квалификации и так далее.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Six;