import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/ledokol4.jpg';

import styles from "../Ledokol.module.scss";

const Ledokol4 = () => {
    return (
        <>
            <Helmet>
                <title>Георгий Васильевич Лазо</title>
            </Helmet>
            <main className={styles.poemsMain}>
                <div className={styles.imgContainer}>
                    <img src={image} alt="Изображение" />
                </div>
            </main>
        </>
    )
}

export default Ledokol4;