import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/twenty.jpg';

const Twenty = () => {
    return (
        <>
            <Helmet>
                <title>Инженер по сварке</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Инженер по сварке</h1>
                        <p>Специалист, который занимается
                            разработкой, оптимизацией и контролем
                            сварочных процессов.</p>
                        <p>Он объединяет глубокие теоретические
                            знания в области сварки с практическим
                            опытом для обеспечения эффективности,
                            качества и безопасности сварочных
                            работ, чтобы заниматься организацией
                            технологических процессов и операций
                            со всеми видами сварки.</p>
                        <p>Инженер по сварке хорошо разбирается
                            во всех видах сплавов, в особенностях
                            их сварки, в правилах применения различного оборудования при проведении
                            сварочных работ, в требованиях к организации труда сварщиков. Учитывая многогранность и важность сварочных процессов, он может работать в различных
                            отраслях промышленности.</p>
                        <p><b>Чаще всего трудовые обязанности
                            инженера по сварки сводятся
                            к следующему: </b>разработка новых
                            способов получения различных сплавов
                            и их внедрение в производство, подготовка к проведению сварочных работ,
                            контроль за соблюдением технологии
                            сварки, за соблюдением техники безопасности и правил эксплуатации любых
                            станков, автоматов и механизмов, используемых в процессе сварки, а также
                            соответствующего программного обеспечения, контроль за рациональным
                            использованием расходных материалов,
                            исследование различных сплавов
                            и способов сварки на предмет усовершенствования имеющихся технологий
                            или создания новых и так далее.</p>
                        <p><b>Личные качества: </b>аналитический склад
                            ума, умение трудится в режиме многозадачности, стрессоустойчивость, концентрация, трудолюбие, коммуникативные
                            способности и другие качества.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Twenty;