import React from 'react';

import { Helmet } from 'react-helmet';

import image from '../../assets/image/nine.jpg';

const Nine = () => {
    return (
        <>
            <Helmet>
                <title>Электрогазосварщик</title>
            </Helmet>
            <main className='main'>
                <div className='intro'>
                    <img src={image} alt="Изображение" />
                </div>
                <section>
                    <div className='info-block'>
                        <h1>Электрогазосварщик</h1>
                        <p>Специалист, который осуществляет
                            сварку различных изделий из металла
                            (узлов металлоконструкций, трубопровода, деталей машин и механизмов и так
                            далее).</p>
                        <p>Он использует электрические и/или газовые сварочные аппараты для соединения металлических элементов, для создания прочных сварных швов и выполнения
                            различных сварочных работ.</p>
                        <p>Работа электрогазосварщика важна для
                            создания прочных металлоконструкций
                            и обеспечения их надежности.
                        </p>
                        <p>На ГХК электрогазосварщик, например,
                            обеспечивает сборку деталировки изделия «ампула ПТ», которая предназначена
                            для хранения пучков твэлов отработавшего ядерного топлива РБМК-1000.
                            Он на стенде сборки корпуса собирает
                            ручной аргонной электрогазосваркой
                            деталировку, то есть к трубе изделия
                            приваривает горловину, сборку, дно,
                            демпфер на прихватках. После этого
                            перемещает собранный корпус ампулы
                            на стенд автоматической аргонодуговой
                            сварки для сваривания кольцевого шва.
                            Кроме того, электрогазосварщик занимается подваркой швов при необходимости.
                            Электрогазосварщики, работающие на
                            Горно-химическом комбинате, специализируются на аргонодуговой сварке.
                            Навыки владения данным видом сварки
                            они получают благодаря сложившейся на
                            предприятие системе наставничества.</p>
                        <p>Профессия электрогазосварщика подходит людям с определенными склонностями, интересами и качествами.</p>
                        <p><b>Вот несколько характеристик, которые
                            могут быть полезными для успешной
                            карьеры в этой области: </b>техническое
                            мышление, точность и внимание к деталям, физическая выносливость, устойчивость к стрессу, координация движений,
                            интерес к технике и металлообработке,
                            способность к обучению, ответственность и безопасность, самообразование.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Nine;